<template>
  <div class="wrap">
    <commonPageBanner
      url="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/Banner_tree.png"
    />
    <div class="content_area">
      <searchActivity
        v-model="queryParams.companyName"
        @search="search"
        class="search-wrap"
      />
      <div class="big_box">
        <template v-if="companyList.length != 0">
          <item
            v-for="(item, i) in companyList"
            :key="i"
            :item="item"
            :index="i"
            @countAdd="countAdd"
            class="item"
          ></item>
        </template>
        <div class="loading_all_center">
        <isAllDataTip
            v-if="total != 0 && total == companyList.length"
        ></isAllDataTip>
        <isLoadingTip v-if="loading"></isLoadingTip>
      </div>
      <noDataImg v-if="companyList.length == 0 && !loading"/>
      </div>
    </div>
  </div>
</template>

<script>
import commonPageBanner from "~tcb/components/layouts/pageBanner";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import item from "~tcb/components/acitvity/item";
import searchActivity from "~tcb/components/acitvity/searchActivity";
import noDataImg from "@/baseComponents/noDataImg";
export default {
  components: {
    commonPageBanner,
    searchActivity,
    isLoadingTip,
    isAllDataTip,
    noDataImg,
    item,
  },
  metaInfo: {
    title: "培训活动",
  },
  data() {
    return {
      total: 0,
      currentPage: 0,
      companyList: [],
      loading: false,
      queryParams: {
        companyName: "",
      },
      companyName: "",
      filters: {
        city: {},
        unitType: null,
        checkYear: null,
        checkClass: [],
        checkCompaines: [],
        checkLine: [],
      },
      cityType: 0,
    };
  },
  computed: {},
  created() {},
  watch: {
    "$route.query.parameter"() {
      if (this.$route.query.parameter) {
        this.queryParams.companyName = this._decode(
          this.$route.query.parameter
        ).searchVal;
      } else {
        this.queryParams.companyName = "";
      }
      this.currentPage = 0;
      this.getList(1);
    },
  },
  mounted() {
    if (this.$route.query.parameter) {
      this.queryParams.companyName = this._decode(
        this.$route.query.parameter
      ).searchVal;
    }
    this.getList(1);
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
    countAdd(row) {
      this.companyList[row.index].views += 1;
    },
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    async getList(type) {
      window.removeEventListener("scroll", this.scrollEvent, false);
      //type 1是搜索赋值 2是下拉拼接
      this.loading = true;
      let params = {
        start: this.currentPage,
        limit: 10,
        is_top: 1,
        is_show: 1,
        source: this.PJSource,
        user_id: this.USER_INFO.id,
        name: this.queryParams.companyName,
        company_id: this.USER_INFO.company_id,
      };

      let res = await this.$store.dispatch(
        "API_index/association_event",
        params
      );
      if (res.success) {
        window.addEventListener("scroll", this.scrollEvent, false);

        this.currentPage++;
        this.loading = false;
        if (type === 1) {
          this.companyList = res.data;
        } else {
          this.companyList = this.companyList.concat(res.data);
        }
        this.total = res.total;
      }
    },
    search() {
      this.currentPage = 0;
      this.getList(1);
    },
    handleCurrentChange() {},
    scrollEvent() {
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight - 354
      ) {
        if (this.total != this.companyList.length) {
          this.getList(2);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fafafa;
}

.content_area {
  .search-wrap {
    text-align: right;
    margin-bottom: 16px;
  }
}
.big_box {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
}
.item {
  margin-right: 13px;
  margin-bottom: 20px;
}
.item:nth-child(4n) {
  margin-right: 0;
}
.loading_all_center {
    padding: 15px;
    width: 100%;
    text-align: center;
  }
</style>
